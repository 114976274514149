<template>
  <div>
    <div v-if="isLoaded" class="card">

      <!-- Conteneur pour la note -->
      <div class="note-container">
        <p style="color: red;"><strong>{{ $t('pages.culture_create.explanation.note') }}</strong></p>
      </div>

      <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid}">
        <form @submit.prevent="handleSubmit(submitForm)">

          <!-- Name -->
          <div class="max-w-sm">
            <ValidationProvider :name="$t('pages.culture_create.fields.name')" rules="required|max:255" v-slot="{errors}">
              <label class="form__label">{{ $t('pages.culture_create.fields.name') }}<Required/></label>
              <input v-model="form.name" class="form__input w-full" type="text">
              <p class="form__error">{{ errors[0] }}</p>
            </ValidationProvider>
          </div>

          <!-- Cultivation type (outside / inside) -->
          <div class="mt-4">
            <label class="form__label">{{ $t('pages.culture_create.fields.cultureType') }}<Required/></label>
            <input id="radio-outside" type="radio" value="1" v-model.number="form.outside">
            <label for="radio-outside" class="text-gray-600"> {{ $t('pages.culture_create.fields.outside') }}</label>

            <input id="radio-inside" class="ml-4" type="radio" value="0" v-model.number="form.outside">
            <label for="radio-inside" class="text-gray-600"> {{ $t('pages.culture_create.fields.inside') }}</label>
          </div>

          <!-- Espèce -->
          <div class="mt-4 flex space-x-4">
            <div class="w-40">
              <label class="form__label">{{ $t('pages.culture_create.fields.species') }}<Required/></label>
              <select v-model.number="form.speciesId" @change="updateVarieties" class="form__input w-full">
                <option v-for="cultureType in cultureTypes" :key="cultureType.id" :value="cultureType.id">
                  {{ $t('backend_trans_keys.' + cultureType.typeTransKey) }}
                </option>
              </select>
            </div>

            <!-- Variété -->
            <div class="w-48">
              <label class="form__label">{{ $t('pages.culture_create.fields.variety') }}<Required/></label>
              <select v-model.number="form.varietyId" class="form__input w-full">
                <option v-for="variety in filteredVarieties" :key="variety.id" :value="variety.id">
                  {{ $t('backend_trans_keys.' + variety.varietyTransKey) }}
                </option>
              </select>
            </div>
          </div>

          <!-- Date de plantation -->
          <div class="mt-4 max-w-sm">
            <ValidationProvider :name="$t('pages.culture_create.fields.plantationDate')" v-slot="{ errors }">
              <label class="form__label">{{ $t('pages.culture_create.fields.plantationDate') }}</label>
              <input v-model="form.plantationDate" class="form__input w-full" type="date">
              <p class="form__error">{{ errors[0] }}</p>
            </ValidationProvider>
          </div>

          <!-- Début des mesures -->
          <div class="mt-4 max-w-sm">
            <ValidationProvider :name="$t('pages.culture_create.fields.nouaisonDate')" v-slot="{ errors }">
              <label class="form__label">{{ $t('pages.culture_create.fields.nouaisonDate') }}</label>
              <input v-model="form.nouaisonDate" class="form__input w-full" type="date">
              <p class="form__error">{{ errors[0] }}</p>
            </ValidationProvider>
          </div>

          <!-- Titre Phénologie-->
          <div class="mt-4 max-w-3xl border border-gray-300 rounded p-4">
            <h3 class="text-lg font-bold underline">{{ $t('pages.culture_create.fields.phenology') }}</h3>

            <!-- Pleine floraison -->
            <div class="flex space-x-2 mt-4"> 
              <div class="flex-1 max-w-xs">
                <label class="form__label">{{ $t('pages.culture_create.fields.fullBloom') }}</label>
                <input v-model="form.fullBloom" class="form__input" type="date">
              </div>

              <!-- Stade T -->
              <div class="flex-1 max-w-xs">
                <label class="form__label">{{ $t('pages.culture_create.fields.stageT') }}</label>
                <input v-model="form.stadeT" class="form__input" type="date">
              </div>

              <!-- Prévision récolte -->
              <div class="flex-1 max-w-xs">
                <label class="form__label">{{ $t('pages.culture_create.fields.harvestForecast') }}</label>
                <input v-model="form.harvestForecast" class="form__input" type="date">
              </div>
            </div>
          </div>

          <!-- Carte interactive -->
          <div class="mt-4">
            <label class="form__label">{{ $t('pages.culture_create.fields.interactiveMapType.title') }}<Required/></label>
            <select v-model.number="form.interactiveMapType" class="form__input cursor-pointer">
              <option value="0">{{ $t('pages.culture_create.fields.interactiveMapType.none') }}</option>
              <option value="1">{{ $t('pages.culture_create.fields.interactiveMapType.geo') }}</option>
              <option value="2">{{ $t('pages.culture_create.fields.interactiveMapType.custom') }}</option>
            </select>

            <!-- Upload custom map file -->
            <div v-if="form.interactiveMapType === 2" class="mt-4">
              <div v-if="culture.hasGeoMap === false" class="block">
                <label class="form__label">{{ $t('pages.culture_edit.actualMapImage') }} </label>
                <img :src="apiUploadMapsRoute+'/'+culture.mapImageName" width="100">
                <label class="form__label">{{ $t('pages.culture_edit.newMapImage') }} </label>
              </div>
              <div class="flex items-center">
                <!-- Full Drop zone -->
                <ValidationProvider name="fileDrop" v-slot="{errors}">
                  <file-drop-zone @onSuccessUploadFile="onSuccessUploadFile" @onError="onFileDropError" @onDeleteUploadFile="onDeleteUploadFile"/>
                  <!-- Error -->
                  <p class="form__error">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <!-- Validate button -->
          <button class="mt-6 btn" :class="{ 'btn--success': !invalid,'btn--disabled': invalid }"><i class="fas fa-check mr-2"></i>{{ $t('pages.culture_edit.validateBtn') }}</button>
        </form>

        <!-- Ajout du texte explicatif -->
        <div class="mt-4 text-gray-600 explanatory-text">
          <h3><u>{{ $t('pages.culture_create.explanation.descriptionTitle') }}</u></h3>

          <p><strong>{{ $t('pages.culture_create.explanation.plantationDateDescr').split(':')[0] }} :</strong>
            {{ $t('pages.culture_create.explanation.plantationDateDescr').split(':').slice(1).join(':') }}
          </p>

          <p><strong>{{ $t('pages.culture_create.explanation.nouaisonDateDescr').split(':')[0] }} :</strong>
            {{ $t('pages.culture_create.explanation.nouaisonDateDescr').split(':').slice(1).join(':') }}
          </p>
  
          <p><strong>{{ $t('pages.culture_create.explanation.phenologyDescr').split(':')[0] }} :</strong>
            {{ $t('pages.culture_create.explanation.phenologyDescr').split(':').slice(1).join(':') }}
          </p>
  
          <p><strong>{{ $t('pages.culture_create.explanation.fullBloomDescr').split(':')[0] }} :</strong>
            {{ $t('pages.culture_create.explanation.fullBloomDescr').split(':').slice(1).join(':') }}
          </p>
  
          <p><strong>{{ $t('pages.culture_create.explanation.stageTDescr').split(':')[0] }} :</strong>
            {{ $t('pages.culture_create.explanation.stageTDescr').split(':').slice(1).join(':') }}
          </p>
  
          <p><strong>{{ $t('pages.culture_create.explanation.harvestForecastDescr').split(':')[0] }} :</strong>
            {{ $t('pages.culture_create.explanation.harvestForecastDescr').split(':').slice(1).join(':') }}
          </p>
  
          <p v-html="$t('pages.culture_create.explanation.agrometeoLink')"></p>
  
          <p><strong>{{ $t('pages.culture_create.explanation.interactiveMapDescr').split(':')[0] }} :</strong>
            {{ $t('pages.culture_create.explanation.interactiveMapDescr').split(':').slice(1).join(':') }}
          </p>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import Required from "../../../components/forms/Required";
import {
  API_CULTURE_TYPES_INDEX,
  API_CULTURES_SHOW2, API_CULTURES_UPDATE, API_UPLOAD_MAPS_ROUTE
} from "../../../utils/constants";
import {apiGetRequest, apiPostRequest, displayAlertSuccess} from "../../../utils";
import FileDropZone from "../../../components/forms/FileDropZone";
import moment from "moment";

export default {
  name: "CultureEdit",
  components: {Required, FileDropZone},
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  data: function() {
    return {
      isLoaded: false,
      form: {
        farmerId: 0,
        name: null,
        nouaisonDate: null,
        outside: 1,
        speciesId: -1,
        varietyId: -1,
        interactiveMapType: 0,
        newMapFile: null,
        plantationDate: null,
        fullBloom: null,
        stadeT: null,
        harvestForecast: null
      },
      culture: null,
      cultureTypes: null,
      apiUploadMapsRoute: API_UPLOAD_MAPS_ROUTE,
      filteredVarieties: []
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    updateVarieties() {
      // Filtrer les variétés en fonction de l'espèce sélectionnée
      const selectedCultureType = this.cultureTypes.find(ct => ct.id === this.form.speciesId);
      this.filteredVarieties = selectedCultureType ? selectedCultureType.varieties : [];
      
      // Si aucune variété n'est trouvée, réinitialiser la sélection de variété
      if (this.filteredVarieties.length > 0) {
        this.form.varietyId = this.filteredVarieties[0].id; // Sélectionner la première variété par défaut
      } else {
        this.form.varietyId = -1; // Réinitialiser si aucune variété n'est disponible
      }
    },
    /**
     * Load current culture data and all available varieties
     */
    loadData: function() {
      this.isLoaded = false
      let showCulturePromise = apiGetRequest(API_CULTURES_SHOW2(this.id))
      let allVarietiesPromise = apiGetRequest(API_CULTURE_TYPES_INDEX)

      Promise.all([showCulturePromise, allVarietiesPromise])
        .then((res) => {
          this.culture = res[0].data
          this.cultureTypes = res[1].data

          // Careful month in mysql are [1-12] and in js [0-11]
          let nouaisonDate = this.culture.nouaisonDate
          if(nouaisonDate !== null){
            nouaisonDate[1]--
            nouaisonDate = moment.utc(nouaisonDate).format("YYYY-MM-DD") 
          }
          //Date for plantationDate
          let plantationDate = this.culture.plantationDate
          if(plantationDate !== null){
            plantationDate[1]--
            plantationDate = moment.utc(plantationDate).format("YYYY-MM-DD")
          }
          //Date for fullBloom
          let fullBloom = this.culture.fullBloom
          if(fullBloom !== null){
            fullBloom[1]--
            fullBloom = moment.utc(fullBloom).format("YYYY-MM-DD")
          }
          //Date for stadeT
          let stadeT = this.culture.stadeT
          if(stadeT !== null){
            stadeT[1]--
            stadeT = moment.utc(stadeT).format("YYYY-MM-DD")
          }
          //Date for harvestForecast
          let harvestForecast = this.culture.harvestForecast
          if(harvestForecast !== null){
            harvestForecast[1]--
            harvestForecast = moment.utc(harvestForecast).format("YYYY-MM-DD")
          }
          const CultureType = this.cultureTypes.find(ct => ct.typeTransKey === this.culture.typeTransKey);
          const VarietyType = CultureType.varieties.find(ct => ct.varietyTransKey === this.culture.varietyTransKey);
          

          // Fill the form with old value
          this.form.farmerId = this.culture.farmerId
          this.form.name = this.culture.name
          this.form.outside = this.culture.outside ? 1 : 0
          this.form.speciesId = CultureType.id
          this.filteredVarieties = CultureType ? CultureType.varieties : [];
          this.form.varietyId = VarietyType.id
          this.form.nouaisonDate = nouaisonDate
          this.form.interactiveMapType = this.culture.hasGeoMap === null ? 0 : this.culture.hasGeoMap === true ? 1 : 2
          this.form.plantationDate = plantationDate
          this.form.fullBloom = fullBloom
          this.form.stadeT = stadeT
          this.form.harvestForecast = harvestForecast    

          this.isLoaded = true
          if(this.culture.hasGeoMap === false){
            try{
              document.getElementById('activeMap').style.backgroundImage = "url(http://localhost:3000/upload/maps/"+this.culture.mapImageName+")";
              // eslint-disable-next-line no-empty
            }catch (e) {}

          }
        })
    },
    onFileDropError: function(error){
      this.form.newMapFile = null

      const errors = this.$refs.form.errors
      errors['fileDrop'] = error.message
      this.$refs.form.setErrors(errors)
    },
    onSuccessUploadFile: function (file) {
      // Reset old file drop error
      const errors = this.$refs.form.errors
      errors['fileDrop'] = null
      this.$refs.form.setErrors(errors)

      this.form.newMapFile = file
    },
    onDeleteUploadFile: function(){
      this.form.newMapFile = null
    },
    /**
     * Submit the form
     */
    submitForm: function() {
      if(this.form.interactiveMapType === 2 && this.culture.mapImageName === null && this.form.newMapFile === null){
        const errors = this.$refs.form.errors
        errors['fileDrop'] = this.$t('pages.culture_create.errorMapFileRequired')
        this.$refs.form.setErrors(errors)
      }else{
        const formData = new FormData()
        formData.append('farmerId', this.form.farmerId)
        formData.append('name', this.form.name)
        formData.append('outside', this.form.outside)
        formData.append('varietyId', this.form.varietyId)
        if(this.form.nouaisonDate !== null){
          formData.append('nouaisonDate', this.form.nouaisonDate)
        }
        if(this.form.plantationDate !== null){
          formData.append('plantationDate', this.form.plantationDate)
        }
        if(this.form.fullBloom !== null){
          formData.append('fullBloom', this.form.fullBloom)
        }
        if(this.form.stadeT !== null){
          formData.append('stadeT', this.form.stadeT)
        }
        if(this.form.harvestForecast !== null){
          formData.append('harvestForecast', this.form.harvestForecast)
        }
        formData.append('interactiveMapType', this.form.interactiveMapType)
        if(this.form.interactiveMapType === 2 && this.form.newMapFile !== null){
          formData.append('customMapFile', this.form.newMapFile, this.form.newMapFile.name)
        }

        apiPostRequest(API_CULTURES_UPDATE(this.id), formData, true)
            .then(() => {
              const successMsg = this.$t('pages.culture_edit.success', {name: this.form.name})
              displayAlertSuccess(successMsg)
              //Redirection après avoir cliquer sur le bouton "Valider"
              this.$router.push({name: "culture_index", params: {id: this.id}})
            })
            .catch()
      }
    }
  }
}
</script>

<style scoped>
.explanatory-text {
  position: absolute; 
  top: 140px; 
  right: 70px; 
  width: 650px; 
  background-color: white; 
  padding: 15px; 
  border: 1px solid #ccc; 
  z-index: 10; 
}
</style>
